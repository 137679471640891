<script>
import emailjs from 'emailjs-com'
import Button from '../reusable/Button.vue'
import FormInput from '../reusable/FormInput.vue'
import FormTextarea from '../reusable/FormTextarea.vue'
import SuccessMessage from '../reusable/SuccessMessage.vue'

export default {
  components: {
    Button,
    FormInput,
    FormTextarea,
    SuccessMessage
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      showSuccessMessage: false,
      successMessage: ''
    };
  },  
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          'service_koczj4s',
          'template_dc5d1ns',
          this.$refs.form,
          'olonGwROPH1KUz5kS'
        )
        .then((result) => {
          console.log('SUCCESS!', result.text);
          this.showSuccessMessage = true // Show success message
          this.successMessage = ""
          this.resetForm() // Reset the form fields
          this.storeSuccessMessage() // Store the success message
          setTimeout(() => {
            this.refreshPage()
          }, 9000)
          // this.refreshPage() // Refresh the page
        })
        .catch((error) => {
          console.log('FAILED...', error.text);
        });
    },
    resetForm() {
      this.name = ''
      this.email = ''
      this.subject = ''
      this.message = ''
    },
    storeSuccessMessage() {
      // Store success message in localStorage
      // const successMessage = this.$i18n.t('email_sent_success')
      localStorage.setItem('successMessage', '');
    },
    refreshPage() {
      location.reload();
    },

    dismissSuccesMessage() {
      this.showSuccessMessage = false
    }
  },
  // mounted() {
  //   // Check if success message exists in localStorage
  //   if (localStorage.getItem('successMessage')) {
  //     this.showSuccessMessage = true
  //     this.successMessage = localStorage.getItem('successMessage')
  //     // Remove success message from localStorage
  //     localStorage.removeItem('successMessage')
  //   }
  // }
};
</script>

<template>
  <div class="w-full md:w-1/2">
    <SuccessMessage v-if="showSuccessMessage" :message="$t('email_sent_success')" @dismiss="dismissSuccessMessage" />
    <div class="leading-loose max-w-xl m-4 p-7 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left">
      <p class="font-general-regular text-primary-dark dark:text-primary-light text-2xl mb-8">
        {{ $t('contact_title') }}
      </p>
      <form ref="form" @submit.prevent="sendEmail" class="font-general-regular space-y-7">
        <FormInput
          :autofocus="true"
          :label="$t('full_name')"
          inputIdentifier="from_name"
          v-model="name" />

        <FormInput
          inputIdentifier="user_email"
          inputType="email"
          label="Email"
          v-model="email" />

        <FormInput
          :label="$t('subject')"
          inputIdentifier="user_subject"
          inputType="subject"
          v-model="subject" />
          
        <FormTextarea
          :label="$t('message')"
          textareaIdentifier="message"
          v-model="message" />
        <div>
          
          <Button
            :title="$t('send')"
            class="px-4 py-2.5 text-white tracking-wider bg-indigo-500 dark:bg-pink-500 hover:bg-indigo-600 dark:hover:bg-pink-600 focus:ring-1 focus:ring-indigo-900 rounded-lg duration-500"
            type="submit"
            aria-label="Send Message"
          />
        </div>
      </form>
    </div>
  </div>
</template>



<style lang="scss" scoped></style>