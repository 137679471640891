<script>
import feather from 'feather-icons'
import AppHeader from './components/shared/AppHeader'
import AppFooter from './components/shared/AppFooter'
import { useHead } from '@vueuse/head'
import CookieBanner from '@/components/CookieBanner.vue'
import SuccessMessage from '@/components/reusable/SuccessMessage.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    CookieBanner,
    SuccessMessage
  },
  data() {
    return {
      appTheme: localStorage.getItem('theme'),
      cookieAccepted: localStorage.getItem('cookieAccepted') === 'true',
    };
  },
  mounted() {
    feather.replace();
    this.updateMeta();
    this.checkCookieConsent();
  },
  updated() {
    feather.replace();
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.updateMeta();
      },
    },
  },
  methods: {
    updateMeta() {
      const { $t } = this.$root;
      useHead({
        title: $t('meta.title'),
        meta: [
          {
            name: 'description',
            content: $t('meta.description'),
          },
          {
            name: 'keywords',
            content: $t('meta.keywords'),
          },
          {
            name: 'author',
            content: 'Farhad Towfighian, www.fartow.se',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
          {
            property: 'og:title',
            content: $t('meta.ogtitle'),
          },
          {
            property: 'og:description',
            content: $t('meta.ogdescription'),
          },
        ],
      });
    },
    checkCookieConsent() {
      this.cookieAccepted = localStorage.getItem('cookieAccepted') === 'true';
    },
    onCookieAccept() {
      this.cookieAccepted = true;
      localStorage.setItem('cookieAccepted', 'true');
    },
  },
};
</script>

<template>
	<div :class="appTheme" class="pt-0.5">
		<!-- App header -->
		<AppHeader />

		<!-- Render active component contents with vue transition -->
		<!-- <transition name="fade" mode="out-in">
			<router-view :theme="appTheme" />
		</transition> -->
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :theme="appTheme" />
      </transition>
    </router-view>

		<!-- Scroll to top -->
		<back-to-top
			visibleoffset="500"
			right="30px"
			bottom="20px"
			class="shadow-lg"
		>
			<i data-feather="chevron-up"></i>
		</back-to-top>

    <!-- Cookie consent banner -->
<div class="fixed bottom-0 left-0 w-full">
  <CookieBanner
    v-if="!cookieAccepted"
    :message="$t('cookie_banner_message')"
    :buttonMessage="$t('cookie_banner_button')"
    @confirm="onCookieAccept"
  />
</div>

		<!-- App footer -->
		<AppFooter />
	</div>
</template>

<style>
#app {
	font-family: Oswald, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

.vue-back-to-top {
	@apply p-2 bg-indigo-500 dark:bg-pink-600 hover:bg-indigo-600 dark:hover:bg-pink-700 text-white transition
        duration-500
        ease-in-out
        transform
        hover:-translate-y-1 hover:scale-110;
	border-radius: 50%;
	font-size: 22px;
	line-height: 22px;
}

.fade-enter-active {
	animation: coming 0.4s;
	animation-delay: 0.2s;
	opacity: 0;
}

.fade-leave-active {
	animation: going 0.4s;
}

@keyframes going {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-10px);
		opacity: 0;
	}
}

@keyframes coming {
	from {
		transform: translateX(-10px);
		opacity: 0;
	}

	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
</style>
