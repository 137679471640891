<template>
  <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
    <div>
      <strong class="font-bold">{{ $t('success') }}</strong>
      <span class="block sm:inline">{{ message }}</span>
    </div>
    <button class="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 dark:bg-pink-600 hover:bg-indigo-700 dark:hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="onConfirm">
      {{ buttonMessage }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    buttonMessage: {
      type: String,
      default: 'Got it!'
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>

<style scoped>
.alert {
  /* Add any additional styling or customization here */
}
</style>
