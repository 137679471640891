<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'fartow.se',
			author: 'Farhad Towfighian',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div
			class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
		>
			&copy; {{ copyrightDate }}.
			<a
				href="https://www.fartow.se"
				target="__blank"
				class="font-general-regular hover:underline hover:text-indigo-600 dark:hover:text-pink-500 duration-500"
			>
				{{ projectName }}
			</a>
			- {{ $t('develped_by') }}
			<a
				href="https://www.fartow.se"
				target="__blank"
				class="font-general-regular text-secondary-dark dark:text-secondary-light uppercase hover:underline hover:text-indigo-600 dark:hover:text-pink-500 duration-500"
				>{{ author }}</a
			>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
