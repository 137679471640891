<template>
  <Carousel :itemsToShow="carouselSettings.itemsToShow" :wrapAround="carouselSettings.wrapAround" :transition="carouselSettings.transition" :autoplay="carouselSettings.autoplay">
    <Slide v-for="item in tools" :key="item.id">
      <img :src="item.img" :alt="item.title">
    </Slide>
    <Pagination />
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'CarouselVue',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
			tools: [
				{
					id: 1,
					title: 'Intellij',
					img: require('@/assets/images/brands/intellij_color.png'),
				},
				{
					id: 2,
					title: 'VsCode',
					img: require('@/assets/images/brands/vscode_color.png'),
				},
				{
					id: 3,
					title: 'Docker',
					img: require('@/assets/images/brands/docker_color.png'),
				},
				{
					id: 4,
					title: 'Git',
					img: require('@/assets/images/brands/git_color.png'),
				},
				{
					id: 5,
					title: 'MySql',
					img: require('@/assets/images/brands/mysql_color.png'),
				},
				{
					id: 6,
					title: 'Postman',
					img: require('@/assets/images/brands/postman_color.png'),
				},
				{
					id: 7,
					title: 'Adobe Photoshop',
					img: require('@/assets/images/brands/photoshop_color.png'),
				},
				{
					id: 8,
					title: 'Adobe Illustrator',
					img: require('@/assets/images/brands/illustrator_color.png'),
				},
			],
      carouselSettings: {
        itemsToShow: 3.95,
        wrapAround: true,
        transition: 500,
        autoplay: 2000,
      },
    };
  },
  created() {
    // Update carousel settings based on screen size
    window.addEventListener('resize', this.updateCarouselSettings);
    this.updateCarouselSettings();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateCarouselSettings);
  },
  methods: {
  updateCarouselSettings() {
    const isMobile = window.innerWidth < 768;

    // Adjust carousel settings for different screen sizes
    if (isMobile) {
      // Mobile screens
      this.carouselSettings.itemsToShow = 1;
      this.carouselSettings.wrapAround = true;
      this.carouselSettings.transition = 500;
      this.carouselSettings.autoplay = 2000; // Disable autoplay for mobile
    } else if (window.innerWidth < 1024) {
      // Tablet screens
      this.carouselSettings.itemsToShow = 2.5;
      this.carouselSettings.wrapAround = true;
      this.carouselSettings.transition = 500;
      this.carouselSettings.autoplay = 2000;
    } else {
      // Desktop screens
      this.carouselSettings.itemsToShow = 4.95;
      this.carouselSettings.wrapAround = true;
      this.carouselSettings.transition = 500;
      this.carouselSettings.autoplay = 2000;
    }
  },
}
});
</script>

<style scoped>
.carousel__slide {
  padding: 10px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>
