<script>
import AboutMe from '@/components/about/AboutMe';
import AboutCounter from '@/components/about/AboutCounter';
import feather from 'feather-icons';
import CarouselSkills from '../components/carousel/CarouselSkills.vue';
import CarouselTools from '../components/carousel/CarouselTools.vue';


export default {
	name: 'About',
	components: {
		AboutMe,
		AboutCounter,
    CarouselSkills,
    CarouselTools
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<div>
		<!-- About me -->
		<div class="container mx-auto">
			<AboutMe />
		</div>

		<!-- About counter -->
		<AboutCounter />

		<!-- Carousels -->
		<div class="mt-10 sm:mt-20">
      <p
        class="font-general-regular text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
      >
      {{ $t('carouel_skills_heading') }}
      </p>
		<div class="mt-10 sm:mt-14">
    <CarouselSkills />
    </div>
    </div>

    <div class="mt-10 sm:mt-20">
      <p
        class="font-general-regular text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
      >
      {{ $t('carouel_tools_heading') }}
      </p>
		<div class="mt-10 sm:mt-14">
    <CarouselTools />
    </div>
  </div>
	</div>
</template>

<style scoped></style>
