import { createApp } from 'vue';
import { createHead } from "@vueuse/head"
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import CookieConsent from 'vue-cookieconsent-component';

const feather = require('feather-icons');
feather.replace();

const i18n = createI18n({
  locale: 'sv', 
  messages: {
    en: require('./locales/en.json'),
    sv: require('./locales/sv.json'),
  },
});
const head = createHead()

const app = createApp(App)
  .use(i18n)
  .use(head)
	.use(router)
	.use(BackToTop)

  app.config.globalProperties.$t = i18n.global.t;
  app.component('CookieConsent', CookieConsent);

	app.mount('#app');

const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}