<!-- <template>
  <select v-model="$i18n.locale">
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale }}
    </option>
  </select>
</template>

<script>
export default {
  name: "TranslationSwitcher",
  data() {
    return { locales: ["sv", "en"] };
  }
};
</script> -->

<template>
  <div class="flag-container">
    <a
      href="#"
      v-for="(locale, i) in locales"
      :key="`locale-${i}`"
      @click.prevent="changeLocale(locale)"
      class="flag-link"
      :class="{ 'font-bold': $i18n.locale === locale }"
      aria-label="Language Switcher"
    >
      <img
        v-if="locale === 'en'"
        src="@/assets/flags/us.png"
        alt="English Flag"
        
        class="px-2 py-1"
      />
      <img
        v-else-if="locale === 'sv'"
        src="@/assets/flags/se.png"
        alt="Swedish Flag"
        class="px-2 py-1"
      />
      
    </a>
  </div>
</template>

<style scoped>
</style>


<script>
export default {
  name: "TranslationSwitcher",
  data() {
    return { locales: ['sv', 'en'] };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>