<script>
export default {
	props: ['showModal', 'isOpen'],
};
</script>

<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none">

  <router-link
			to="/"
			class="font-general-regular block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-800 dark:hover:text-pink-800 sm:mx-4 mb-2 sm:py-2 "
			aria-label="Home"
			>{{ $t('home') }}
    </router-link>

    <!-- Activate these if you activate Project section! And activate resp. in Home.vue too-->
		<!-- <router-link
			to="/projects"
			class="font-general-regular block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-800 dark:hover:text-pink-800 sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Projects"
			>{{ $t('projects') }}
    </router-link> -->

		<router-link
			to="/about"
			class="font-general-regular block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-800 dark:hover:text-pink-800 sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="About Me"
			>{{ $t('about') }}
    </router-link>

		<router-link
			to="/contact"
			class="font-general-regular block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-800 dark:hover:text-pink-800 sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Contact"
			>{{ $t('contact') }}
    </router-link>

		<div
			class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
      
			<button
				class="font-general-regular sm:hidden block text-left text-md font-medium bg-indigo-500 dark:bg-pink-500 hover:bg-indigo-600 dark:hover:bg-pink-600 text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-500"
				@click="showModal()"
				aria-label="Hire Me Button">
      {{ $t('hire_me') }}
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
