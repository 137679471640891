<template>
  <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
    <strong class="font-bold">{{ $t('success') }}</strong>
    <span class="block sm:inline">{{ message }}</span>
    <button
      class="absolute top-0 right-0 mt-1 mr-2"
      @click="dismiss"
    >
      <svg class="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.707 10l4.147-4.146a.5.5 0 0 0-.708-.708L10 9.293 5.854 5.147a.5.5 0 0 0-.708.708L9.293 10l-4.147 4.146a.5.5 0 1 0 .708.708L10 10.707l4.146 4.147a.5.5 0 0 0 .708-.708L10.707 10z"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismiss');
    }
  }
};
</script>

<style scoped>
.alert {
  /* Add any additional styling or customization here */
}
</style>
