<script>
export default {
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:'about_me.bio1',
          bold: false
				},
				{
					id: 2,
					bio: 'about_me.bio2',
          bold: false
				},
        {
					id: 3,
					bio: 'about_me.bio3',
          bold: true
				},
        {
					id: 4,
					bio: 'about_me.bio4',
          bold: false
				},
        {
					id: 5,
					bio:'about_me.bio5',
          bold: false
				},
        {
					id: 6,
					bio: 'about_me.bio6',
          bold: false
				},
        {
					id: 7,
					bio: 'about_me.bio7',
          bold: false
				},
        {
					id: 8,
					bio: 'about_me.bio8',
          bold: false
				},
        {
					id: 9,
					bio: 'about_me.bio9',
          bold: true
				},
        {
					id: 10,
					bio: 'about_me.bio10',
          bold: false
				},
        {
					id: 11,
					bio: 'about_me.bio11',
          bold: false
				},
        {
					id: 12,
					bio: 'about_me.bio12',
          bold: false
				},
        {
					id: 13,
					bio: 'about_me.bio13',
          bold: false
				},
        {
					id: 14,
					bio: 'about_me.bio14',
          bold: false
				},
        {
					id: 15,
					bio: 'about_me.bio15',
          bold: false
				},
        {
					id: 16,
					bio: 'about_me.bio16',
          bold: false
				},
        {
					id: 17,
					bio: 'about_me.bio17',
          bold: false
				},
        {
					id: 18,
					bio: 'about_me.bio18',
          bold: false
				},
        {
					id: 19,
					bio: 'about_me.bio19',
          bold: false
				},
        {
					id: 20,
					bio: 'about_me.bio20',
          bold: false
				},
			],
		};
	},
};
</script>

<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About profile image -->
		<div class="w-full sm:w-1/4 mb-7 sm:mb-0">
			<img
				src="@/assets/images/profile.png"
				class="rounded-xl w-96"
				alt=""
			/>
		</div>

		<!-- About details -->
		<div class="w-full sm:w-3/4 text-left">
			<p
				v-for="bio in bios"
				:key="bio.id"
				class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-base">
        <span
					v-if="bio.bold"
					class="font-general-regular mb-4 text-indigo-700 dark:text-pink-700 text-2xl"
					v-html="$t(bio.bio)"
				></span>
				<span v-else v-html="$t(bio.bio)"></span>
			</p>
		</div>
	</div>
</template>