<script>
import AppBanner from '@/components/shared/AppBanner';
// import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
// import AboutClientsVue from '../components/about/AboutClients.vue';
// import Button from '../components/reusable/Button.vue';
// import AboutClientSingle from '../components/about/AboutClientSingle.vue';
import CarouselSkills from '../components/carousel/CarouselSkills.vue';
import CarouselTools from '../components/carousel/CarouselTools.vue';

export default {
	name: 'Home',
	components: {
    AppBanner,
    // ProjectsGrid,
    // AboutClientsVue,
    // Button,
    // AboutClientSingle
    CarouselSkills,
    CarouselTools
},
};
</script>

<template>
  <div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<!-- <ProjectsGrid /> -->
    <!-- <AboutClientsVue /> -->
    <div class="mt-10 sm:mt-20">
      <p
        class="font-general-regular text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
      >
      {{ $t('carouel_skills_heading') }}
      </p>
		<div class="mt-10 sm:mt-14">
    <CarouselSkills />
    </div>
    </div>

    <div class="mt-10 sm:mt-20">
      <p
        class="font-general-regular text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
      >
      {{ $t('carouel_tools_heading') }}
      </p>
		<div class="mt-10 sm:mt-14">
    <CarouselTools />
    </div>
  </div>

		<!-- Load more projects button -->
		<!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/projects"
				class="font-general-regular flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 dark:bg-pink-700 hover:bg-indigo-600 dark:hover:bg-pink-800 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects"
			>
				<Button :title="$t('more_projects')" />
			</router-link>
		</div> -->
	</div>
</template>

<style scoped></style>
